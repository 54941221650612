import { component } from '~/utils/component';
import { debugAPI } from '../debug/debugAPI';
import { instrumentService } from '../services/instruments';
import useSWR from 'swr';
import { FrGa } from '../FrGa/FrGa';
import { ref } from 'valtio';
import { Fragment } from 'react';
import { css } from '@emotion/react';
import { Preset_EntirePage } from '~/pages/heineken_template/_preset/preset_EntirePage';
import { Button, Loader } from '@mantine/core';
export class DomainContextProvider {
    Provider = ref(component(props => {
        const isReady = useSWR('FIXED_KEY', async function fetcher() {
            console.info(`${debugAPI.domain.logger.namespace} 正在準備必要遠端數據...`);
            await instrumentService.fetch();
            console.info(`${debugAPI.domain.logger.namespace} 準備完成，開始 render UI`);
            return true;
        }, {
            keepPreviousData: true,
            refreshWhenHidden: false,
            refreshWhenOffline: false,
            revalidateIfStale: false,
            revalidateOnFocus: false,
            errorRetryCount: 5,
            errorRetryInterval: 1000,
        });
        FrGa.useTimeSpent()
            .calc(() => isReady.data === true)
            ?.done(data => {
            data.ms > 0 && FrGa.event({ 首屏轉圈圈完成: { ms: data.ms } });
        });
        FrGa.useTimeSpent()
            .calc(() => !!isReady.error)
            ?.done(data => {
            data.ms > 0 && FrGa.event({ 首屏轉圈圈失敗: { ms: data.ms } });
        });
        return (<Fragment>
          {isReady.data === true && <Fragment>{props.children}</Fragment>}

          {isReady.error && (<Preset_EntirePage css={css `
                justify-content: center;
                align-content: center;
              `}>
              <div>必要元件載入時發生問題</div>
              <div>Error: {isReady.error?.message}</div>
              <Button onClick={() => {
                    FrGa.event({
                        首屏轉圈圈失敗點擊重整: {},
                    });
                    globalThis.location.reload();
                }}>
                點此重新整理
              </Button>
            </Preset_EntirePage>)}

          {isReady.isLoading && isReady.data === undefined && (<Preset_EntirePage css={css `
                justify-content: center;
                align-content: center;
              `}>
              <Loader color='red' variant='bars' size='xl'/>
            </Preset_EntirePage>)}
        </Fragment>);
    }));
}
