import { isEmpty } from 'lodash';
import { instrumentService } from '../../services/instruments';
import { Entity } from '../abstractions';
import { MarketHours } from '../enumerations/market-hours';
export class Instrument extends Entity {
    symbol;
    name;
    type;
    exchange;
    bigPointValue;
    static create(symbol, name, type, exchange, bigPointValue) {
        return new Instrument(symbol, name, type, exchange, bigPointValue);
    }
    constructor(symbol, name, type, exchange, bigPointValue) {
        super(symbol);
        this.symbol = symbol;
        this.name = name;
        this.type = type;
        this.exchange = exchange;
        this.bigPointValue = bigPointValue;
    }
    get hasFuture() {
        return !isEmpty(instrumentService.getFutureSymbols(this.symbol));
    }
    get underlying() {
        return instrumentService.getUnderlying(this.symbol);
    }
    get sessions() {
        let marketHours;
        if (RegExp(/TF-\d/i).exec(this.symbol.value)) {
            marketHours = this.exchange.hours.find(item => item.equals(MarketHours.TFE_AM));
        }
        if (RegExp(/T[EX]-\d/i).exec(this.symbol.value)) {
            marketHours = this.exchange.hours.find(item => item.equals(MarketHours.TFE_Standard));
        }
        if (this.symbol.value.includes('AM')) {
            marketHours = this.exchange.hours.find(item => item.equals(MarketHours.TFE_AM));
        }
        if (this.symbol.value.includes('PM')) {
            marketHours = this.exchange.hours.find(item => item.equals(MarketHours.TFE_PM));
        }
        if (marketHours === undefined) {
            return this.exchange.hours[0];
        }
        return marketHours;
    }
}
