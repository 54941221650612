import dayAPI from '~/utils/dayAPI';
import { ValueObject } from '../abstractions';
import { DateTimeRange } from './datetime-range';
import { TimeOnly } from './time-only';
export class TradingSession extends ValueObject {
    openingTime;
    closingTime;
    static create(openingTime, closingTime) {
        if (openingTime.dayOfWeek === closingTime.dayOfWeek &&
            closingTime.time.isSameOrBefore(openingTime.time)) {
            throw new Error('Closing time should be after opening time in same day');
        }
        return new TradingSession(openingTime, closingTime);
    }
    constructor(openingTime, closingTime) {
        super();
        this.openingTime = openingTime;
        this.closingTime = closingTime;
    }
    isOpen(date) {
        const baseTime = date || dayAPI();
        const time = TimeOnly.parse(baseTime.format('HH:mm:ss'));
        if (baseTime.weekday() === this.openingTime.dayOfWeek &&
            baseTime.weekday() === this.closingTime.dayOfWeek) {
            return time.isSameOrBetween(this.openingTime.time, this.closingTime.time);
        }
        else {
            if (baseTime.weekday() === this.openingTime.dayOfWeek) {
                return time.isSameOrAfter(this.openingTime.time);
            }
            if (baseTime.weekday() === this.closingTime.dayOfWeek) {
                return time.isSameOrBefore(this.closingTime.time);
            }
        }
        return false;
    }
}
export class IntradayTradingSessions extends ValueObject {
    sessions = [];
    constructor() {
        super();
    }
    toRange() {
        if (!this.any()) {
            throw new Error('No any session in collection');
        }
        const currentTime = dayAPI();
        const currentWeekDay = currentTime.weekday();
        const startTime = this.first().openingTime;
        const endTime = this.last().closingTime;
        let start = dayAPI();
        let end = dayAPI();
        if (startTime.dayOfWeek - currentWeekDay !== 0) {
            start = start.add(startTime.dayOfWeek - currentWeekDay, 'days');
        }
        if (endTime.dayOfWeek - currentWeekDay !== 0) {
            end = end.add(endTime.dayOfWeek - currentWeekDay, 'days');
        }
        start = start.setTime(Number(`${startTime.time.hour}.${startTime.time.minute}`));
        end = end.setTime(Number(`${endTime.time.hour}.${endTime.time.minute}`));
        return DateTimeRange.create(start, end);
    }
    add(session) {
        if (!this.sessions.find(item => item.equals(session)))
            this.sessions.push(session);
        return this;
    }
    addRange(sessions) {
        sessions.forEach(session => this.add(session));
        return this;
    }
    count(predicate) {
        if (predicate) {
            return this.where(predicate).count();
        }
        return this.sessions.length;
    }
    forEach(action) {
        this.sessions.forEach((session, index) => {
            action(session, index, this.sessions);
        });
    }
    any(predicate) {
        if (predicate) {
            return this.sessions.some(predicate);
        }
        return this.sessions.length > 0;
    }
    firstOrDefault() {
        if (this.sessions.length > 0) {
            return this.sessions[0];
        }
        return null;
    }
    lastOrDefault() {
        if (this.sessions.length > 0) {
            return this.sessions[this.sessions.length - 1];
        }
        return null;
    }
    first() {
        if (this.sessions.length > 0) {
            return this.sessions[0];
        }
        throw new Error('No elements in the collection.');
    }
    last() {
        if (this.sessions.length > 0) {
            return this.sessions[this.sessions.length - 1];
        }
        throw new Error('No elements in the collection.');
    }
    where(predicate) {
        const filteredSessions = this.sessions.filter(predicate);
        const newCollection = new IntradayTradingSessions();
        newCollection.addRange(filteredSessions);
        return newCollection;
    }
    splitAsTimestamps(converter, length) {
        const range = this.toRange();
        const maxLength = length || 8;
        const timestamps = [];
        let current = range.start.clone();
        let final = range.end.clone();
        if (range.end.minute() > 0) {
            final = range.end.set('hours', range.end.hour()).set('minute', 0);
        }
        let hoursDiff = 0;
        if (this.sessions.length === 1) {
            hoursDiff = final.diff(current, 'hours');
        }
        else {
            const hours = this.sessions.map(session => {
                const collection = new IntradayTradingSessions();
                collection.add(session);
                const sessionRange = collection.toRange();
                return sessionRange.end.diff(sessionRange.start, 'hours');
            });
            hoursDiff = hours.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
        }
        const hourlyIncrement = Math.ceil(hoursDiff / (maxLength - 1));
        while (current.isBefore(range.end) || current.isSame(range.end)) {
            if (this.sessions.filter(session => session.isOpen(current)).length) {
                timestamps.push(converter(current));
            }
            if (current.minute() > 0) {
                current = current.add(60 - current.minute(), 'minutes');
            }
            else {
                current = current.add(hourlyIncrement, 'hours');
            }
            if (timestamps.length >= maxLength - 1) {
                break;
            }
        }
        timestamps.push(converter(range.end));
        return timestamps;
    }
}
