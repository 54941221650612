import { Enumeration } from '../abstractions';
import { TimeOnly, TradingSession, IntradayTradingSessions, TradingSessionTime, } from '../value-objects';
import { isEmpty } from 'lodash';
export class MarketHours extends Enumeration {
    _sessions = [];
    static Undefined;
    static TWSE;
    static TFE_Standard;
    static TFE_AM;
    static TFE_PM;
    static UsFutureOption;
    static Crypto;
    static SGX;
    static HKF;
    static EUREX;
    static IUS;
    constructor(name, value) {
        super(name, value);
    }
    next(date) {
        const sortedSessions = this._sessions.sort(MarketHours.defaultSorter);
        if (date) {
            const matchedIndex = sortedSessions.findIndex(session => session.isOpen(date));
            if (matchedIndex > -1) {
                const nextSession = sortedSessions[matchedIndex + 1];
                return nextSession;
            }
        }
        else {
            const currentSession = this.current();
            if (currentSession) {
                const currentSessionIndex = sortedSessions.indexOf(currentSession);
                if (currentSessionIndex >= 0 && currentSessionIndex < sortedSessions.length - 1) {
                    return sortedSessions[currentSessionIndex + 1];
                }
            }
        }
        return sortedSessions[0];
    }
    previous(date) {
        throw new Error('Method not implemented.');
    }
    intraday(date) {
        const sessions = new IntradayTradingSessions();
        let lastSession = null;
        if (date) {
            const matchedSessions = this._sessions.filter(session => session.isOpen());
            if (!isEmpty(matchedSessions)) {
                lastSession = matchedSessions[0];
            }
        }
        else {
            lastSession = this.current() || this.previousOf();
            // this.current() !== undefined ? (this.current() as TradingSession) : this.previous()
        }
        if (!lastSession)
            return sessions;
        sessions.add(lastSession);
        const hasMany = !(lastSession.openingTime.time.equals(this.intradayOpening()) &&
            lastSession.closingTime.time.equals(this.intradayClosing()));
        if (hasMany) {
            if (lastSession.openingTime.time.equals(this.intradayOpening())) {
                sessions.add(this.nextOf(lastSession));
            }
            else if (lastSession.closingTime.time.equals(this.intradayClosing())) {
                sessions.add(this.previousOf(lastSession));
            }
        }
        return sessions;
    }
    current(date) {
        return this._sessions.find(session => session.isOpen(date));
    }
    nextOf(session) {
        const sortedSessions = this._sessions.sort(MarketHours.defaultSorter);
        const sessionIndex = session
            ? sortedSessions.findIndex(item => item.equals(session))
            : sortedSessions.findIndex(item => item.isOpen());
        if (sessionIndex !== -1 && sessionIndex < sortedSessions.length - 1) {
            return sortedSessions[sessionIndex + 1];
        }
        return sortedSessions[0];
    }
    previousOf(session) {
        const sortedSessions = this._sessions.sort(MarketHours.defaultSorter);
        let sessionIndex = session
            ? sortedSessions.findIndex(item => item.equals(session))
            : sortedSessions.findIndex(item => item.isOpen());
        if (sessionIndex === -1) {
            sessionIndex = sortedSessions.length;
        }
        if (sessionIndex > 0) {
            return sortedSessions[sessionIndex - 1];
        }
        else {
            return sortedSessions[sortedSessions.length - 1];
        }
    }
    static defaultSorter(sessionA, sessionB) {
        const daysInAWeek = 7;
        const dayOfWeekA = sessionA.openingTime.dayOfWeek;
        const dayOfWeekB = sessionB.openingTime.dayOfWeek;
        const diffA = (dayOfWeekA - sessionA.openingTime.dayOfWeek + daysInAWeek) % daysInAWeek;
        const diffB = (dayOfWeekB - sessionB.openingTime.dayOfWeek + daysInAWeek) % daysInAWeek;
        if (diffA === diffB) {
            const timeA = sessionA.openingTime.time;
            const timeB = sessionB.openingTime.time;
            if (timeA.hour < timeB.hour) {
                return -1;
            }
            else if (timeA.hour > timeB.hour) {
                return 1;
            }
            else {
                if (timeA.minute < timeB.minute) {
                    return -1;
                }
                else if (timeA.minute > timeB.minute) {
                    return 1;
                }
                else {
                    return 0;
                }
            }
        }
        return diffA - diffB;
    }
}
class TwseMarketHours extends MarketHours {
    intradayOpening() {
        return TimeOnly.parse('09:00');
    }
    intradayClosing() {
        return TimeOnly.parse('13:30');
    }
    constructor() {
        super('TWSE Market Hours', 0);
        for (let i = 1; i < 6; i++) {
            const session = TradingSession.create(TradingSessionTime.create(i, TimeOnly.parse('09:00')), TradingSessionTime.create(i, TimeOnly.parse('13:30')));
            this._sessions.push(session);
        }
    }
}
class TfeStandardMarketHours extends MarketHours {
    intradayOpening() {
        return TimeOnly.parse('15:00');
    }
    intradayClosing() {
        return TimeOnly.parse('13:45');
    }
    constructor() {
        super('TfeStandard', 1);
        for (let i = 1; i < 6; i++) {
            const nextWeekDay = i + 1 === 6 ? 1 : i + 1;
            const session1 = TradingSession.create(TradingSessionTime.create(i, TimeOnly.parse('15:00')), TradingSessionTime.create(nextWeekDay, TimeOnly.parse('05:00')));
            const session2 = TradingSession.create(TradingSessionTime.create(nextWeekDay, TimeOnly.parse('08:45')), TradingSessionTime.create(nextWeekDay, TimeOnly.parse('13:45')));
            this._sessions.push(session1);
            this._sessions.push(session2);
        }
    }
}
class TfeAMMarketHours extends MarketHours {
    intradayOpening() {
        return TimeOnly.parse('08:45');
    }
    intradayClosing() {
        return TimeOnly.parse('13:45');
    }
    constructor() {
        super('TfeAM', 2);
        for (let i = 1; i < 6; i++) {
            const session = TradingSession.create(TradingSessionTime.create(i, TimeOnly.parse('08:45')), TradingSessionTime.create(i, TimeOnly.parse('13:45')));
            this._sessions.push(session);
        }
    }
}
class TfePMMarketHours extends MarketHours {
    intradayOpening() {
        return TimeOnly.parse('15:00');
    }
    intradayClosing() {
        return TimeOnly.parse('05:30');
    }
    constructor() {
        super('TfePM', 3);
        for (let i = 1; i < 6; i++) {
            const nextWeekDay = i + 1 === 6 ? 1 : i + 1;
            const session = TradingSession.create(TradingSessionTime.create(i, TimeOnly.parse('15:00')), TradingSessionTime.create(nextWeekDay, TimeOnly.parse('05:00')));
            this._sessions.push(session);
        }
    }
}
class UsFutureOptionMarketHours extends MarketHours {
    intradayOpening() {
        return TimeOnly.parse('06:00');
    }
    intradayClosing() {
        return TimeOnly.parse('05:00');
    }
    constructor() {
        super('CME', 4);
        for (let i = 1; i < 6; i++) {
            const nextWeekDay = i + 1 === 6 ? 1 : i + 1;
            const session = TradingSession.create(TradingSessionTime.create(i, TimeOnly.parse('06:00')), TradingSessionTime.create(nextWeekDay, TimeOnly.parse('05:00')));
            this._sessions.push(session);
        }
    }
}
class CryptoMarketHours extends MarketHours {
    intradayOpening() {
        return TimeOnly.parse('08:00');
    }
    intradayClosing() {
        return TimeOnly.parse('08:00');
    }
    constructor() {
        super('Crypto', 5);
        for (let i = 1; i < 6; i++) {
            const nextWeekDay = i + 1 === 6 ? 0 : i + 1;
            const session = TradingSession.create(TradingSessionTime.create(i, TimeOnly.parse('06:00')), TradingSessionTime.create(nextWeekDay, TimeOnly.parse('05:00')));
            this._sessions.push(session);
        }
    }
}
class SgxMarketHours extends MarketHours {
    intradayOpening() {
        return TimeOnly.parse('09:00');
    }
    intradayClosing() {
        return TimeOnly.parse('05:15');
    }
    constructor() {
        super('SGX:FCH', 6);
        for (let i = 1; i < 6; i++) {
            const nextWeekDay = i + 1 === 6 ? 1 : i + 1;
            const session = TradingSession.create(TradingSessionTime.create(i, TimeOnly.parse('09:00')), TradingSessionTime.create(i, TimeOnly.parse('16:35')));
            this._sessions.push(session);
            const session2 = TradingSession.create(TradingSessionTime.create(i, TimeOnly.parse('17:00')), TradingSessionTime.create(nextWeekDay, TimeOnly.parse('05:15')));
            this._sessions.push(session2);
        }
    }
}
class HkfMarketHours extends MarketHours {
    intradayOpening() {
        return TimeOnly.parse('09:15');
    }
    intradayClosing() {
        return TimeOnly.parse('03:00');
    }
    constructor() {
        super('HKF MarketHours', 6);
        for (let i = 1; i < 6; i++) {
            const nextWeekDay = i + 1 === 6 ? 1 : i + 1;
            const session = TradingSession.create(TradingSessionTime.create(i, TimeOnly.parse('09:15')), TradingSessionTime.create(i, TimeOnly.parse('12:00')));
            this._sessions.push(session);
            const session2 = TradingSession.create(TradingSessionTime.create(i, TimeOnly.parse('13:00')), TradingSessionTime.create(nextWeekDay, TimeOnly.parse('16:30')));
            this._sessions.push(session2);
            const session3 = TradingSession.create(TradingSessionTime.create(i, TimeOnly.parse('17:15')), TradingSessionTime.create(nextWeekDay, TimeOnly.parse('03:00')));
            this._sessions.push(session3);
        }
    }
}
class EurexMarketHours extends MarketHours {
    intradayOpening() {
        return TimeOnly.parse('08:10');
    }
    intradayClosing() {
        return TimeOnly.parse('05:00');
    }
    constructor() {
        super('EUREX MarketHours', 6);
        for (let i = 1; i < 6; i++) {
            const nextWeekDay = i + 1 === 6 ? 1 : i + 1;
            const session = TradingSession.create(TradingSessionTime.create(i, TimeOnly.parse('08:10')), TradingSessionTime.create(nextWeekDay, TimeOnly.parse('05:00')));
            this._sessions.push(session);
        }
    }
}
class IusMarketHours extends MarketHours {
    intradayOpening() {
        return TimeOnly.parse('16:15');
    }
    intradayClosing() {
        return TimeOnly.parse('01:30');
    }
    constructor() {
        super('IUS MarketHours', 7);
        for (let i = 1; i < 6; i++) {
            const nextWeekDay = i + 1 === 6 ? 1 : i + 1;
            const session = TradingSession.create(TradingSessionTime.create(i, TimeOnly.parse('16:15')), TradingSessionTime.create(nextWeekDay, TimeOnly.parse('01:30')));
            this._sessions.push(session);
        }
    }
}
class UndefinedMarketHours extends MarketHours {
    intradayOpening() {
        return TimeOnly.parse('00:00');
    }
    intradayClosing() {
        return TimeOnly.parse('00:00');
    }
    constructor() {
        super('UndefinedMarketHours', 8);
        for (let i = 1; i < 6; i++) {
            const session = TradingSession.create(TradingSessionTime.create(i, TimeOnly.parse('00:00')), TradingSessionTime.create(i, TimeOnly.parse('23:00')));
            this._sessions.push(session);
        }
    }
}
MarketHours.TWSE = new TwseMarketHours();
MarketHours.TFE_Standard = new TfeStandardMarketHours();
MarketHours.TFE_AM = new TfeAMMarketHours();
MarketHours.TFE_PM = new TfePMMarketHours();
MarketHours.UsFutureOption = new UsFutureOptionMarketHours();
MarketHours.Crypto = new CryptoMarketHours();
MarketHours.SGX = new SgxMarketHours();
MarketHours.HKF = new HkfMarketHours();
MarketHours.EUREX = new EurexMarketHours();
MarketHours.IUS = new IusMarketHours();
MarketHours.Undefined = new UndefinedMarketHours();
